(function () {
  'use strict';

  angular
    .module('invitation')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('invitation', {
        url: '/invitation/:creation_code',
        templateUrl: 'invitation/invitation.tpl.html',
        controller: 'InvitationCtrl',
        controllerAs: 'invitation'
      });
  }
}());
